<template>
    <div>
   
        <is-loading-wrapper :loading="is_loading" :loading_msg="'Loading your static portfolio'">

            <v-container fluid >
                <v-row>
                    <v-col cols="12" md="3">
                        <v-sheet rounded="sm">
                            <h3>
                                <v-icon class="ma-0">mdi-format-align-center</v-icon>
                            </h3>
                            <div v-for="l in my_lists" :key="l.id">
                                
                                <v-btn class="mt-2" :color="selected_list_id == l.id ? 'red' : 'default'" elevation="2" small  block @click="select_list(l)" >{{l.name}}</v-btn>
                            </div>
                            
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="9">
                        <v-sheet rounded="sm" v-if="selected_list_id">
                            <my-list-list :list_id="selected_list_id"></my-list-list>
                        </v-sheet>
                    </v-col>
                </v-row>

            </v-container>
        </is-loading-wrapper>
 
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'

import IsLoadingWrapper from '../is-loading-wrapper.vue'
import MyListList from './my-list-list.vue'
export default {
    name: 'view-my-lists-static',
    components:{
        MyListList,
        IsLoadingWrapper,

    },
    emits:[
    ],
    data(){return {
        my_lists:undefined,
        is_loading:true,
        selected_list_id:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_my_lists()
    
    },
    methods:{
        ...mapActions(['static_pair_list_actions']),
        do_get_my_lists(){
                this.static_pair_list_actions({data:{},qs:{action:'get_my_lists'}}).then( r=> {
                    this.my_lists = r.data
                    this.is_loading = false
                })
        },
        select_list(l){
            this.selected_list_id = l.id
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>