<template>
    <div>
        <is-loading-wrapper :loading="is_loading" :loading_msg="'Loading your portfolio'">
            <v-container fluid>
                <v-row>
            
                    <v-col sm="12" md="12" >
                        <div v-if="pair_data">
                            <table>
                                <tr>
                                    <th>id</th>
                                    <th>pair</th>
                                    <th>price</th>
                                    <th v-for="mh in pair_data.metric_headers" :key="mh">{{mh.replaceAll('metric_','').replaceAll('_',' ')}}</th>
                                    <th>    <v-btn class="ma-0" elevation="2" color="default" small block @click="view_builder_dialog= !view_builder_dialog" >...</v-btn></th>
                                </tr>
                                <tr v-for="(pair,i) in pair_data.data" :key="pair.id" @click="toggle_selected_pairs(pair)" :class="{'selected-pair':selected_pairs.includes(pair)}">
                                    <td>{{pair.id}}</td>
                                    <td>
                                        <pair-link-to-page :pair=pair ></pair-link-to-page>
                                    </td>
                                    <td>
                                        <v-sparkline  :smooth="16" :gradient="['#1feaea', '#ffd200','#f72047'  ]" :line-width="3" :value="pair.price_list" auto-draw stroke-linecap="round" ></v-sparkline>
                                    </td>
                                    <td v-for="mh in pair_data.metric_headers" :key="mh">
                                        <table>
                                            <tr v-if="i==0">
                                                <th v-for="(mval, mhead) in pair.metrics[mh]" :key="mhead"> {{mhead.replaceAll('_',' ')}}</th>
                                            </tr>
                                            <tr>
                                                <td v-for="(mval, mhead) in pair.metrics[mh]" :key="mhead">
                                                    <div v-if="mval">
                                                        <div v-if="mhead.includes('seconds')">  <seconds-amount :amount="mval.toFixed(0)"></seconds-amount> </div> 
                                                        <div v-else-if="mhead.includes('base')">  <base-amount :amount="mval"></base-amount></div>
                                                        <div v-else-if="mhead.includes('pct')"> {{mval.toFixed(1)}} %</div>
                                                        <div v-else> {{mval}}</div>
                                                    </div>
                                                    <div v-else >
                                                        -

                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- {{pair.metrics[mh]}} -->
                                    </td>
                                    <td>
                                        
                                    </td>
                                </tr>
                            </table>
                            <!-- {{pair_data}} -->
                        </div>
                        <v-btn color="default" fab x-small @click="open_add_to_list_dialog(pair)" >
                            <!-- <v-icon class="ma-0">mdi-plus</v-icon> -->
                            <v-icon class="ma-0">mdi-format-list-numbered-rtl</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <!-- builder view popup  -->
            <v-dialog v-model="view_builder_dialog"  max-width="90vw" >
                <v-card>
                    <v-card-title>
                    <span class="text-h5"> View Builder</span>
                    </v-card-title>
                    <v-card-text>
                        view builder has been depricated
                        <!-- view_name="view_name" @new_view="(nv)=>{view = nv;do_pair_list_view_custom(); view_builder_dialog = false}"></intel-pair-list-view-builder> -->
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- add to list  -->
            <v-dialog v-model="add_to_list_dialog"  max-width="70vw" >
                <v-sheet rounded="sm" v-if="selected_pairs">
                    <my-list-add-pair :selected_pairs="selected_pairs"></my-list-add-pair>
                </v-sheet>
            </v-dialog>
        </is-loading-wrapper>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from './base-amount.vue';
// import IntelPairListViewBuilder from './static_lists/intel-pair-list-view-builder.vue';
import IsLoadingWrapper from './is-loading-wrapper.vue';
import PairLinkToPage from './pair-link-to-page.vue';
import SecondsAmount from './seconds-amount.vue';
import MyListAddPair from './static_lists/my-list-add-pair.vue';
export default {
    name: 'intel-pair-list-view-custom',
    components:{
        // IntelPairListViewBuilder,
        MyListAddPair,
        PairLinkToPage,
        IsLoadingWrapper,
        BaseAmount,
        SecondsAmount
    },
    emits:[
    ],
    data(){return {
        search:{ str:'' },
        page_amt:10,
        view_args:{'time_delta_seconds':3000, 'time_now': Date.now()/1000},
        view:undefined,
        pair_data:undefined,
        view_builder_dialog:false,
        add_to_list_dialog:false,
        selected_pair:undefined,
        selected_pairs:[],
        is_loading:true
        }
    },
    props: { 
        view_name:{type:String,default:'default_view'},
        //data:{type:Object,default:Object},
        pair_ids:{type:Array,default:Array},
        list_actions:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_view_then_data()
    },
    methods:{
        ...mapActions(['static_pair_list_actions','load_latest_pairs_id_n','pair_list_view_custom','pair_list_views_get_view']),
        do_get_view_then_data(){
                this.pair_list_views_get_view({view_name:this.view_name}).then(r=>{
                    this.view = r.data 
                    this.do_pair_list_view_custom(this.view)
                })
        },
        do_pair_list_view_custom(view){
            this.pair_list_view_custom({data:{ obj_ids:this.pair_ids, view:view }}).then(r=>{
                this.pair_data = r.data
                this.is_loading = false
            })
        },
        open_add_to_list_dialog(pair){
            this.selected_pair = pair
            this.add_to_list_dialog = !this.add_to_list_dialog
        },
        toggle_selected_pairs(pair){
            if(this.selected_pairs.includes(pair)){ this.selected_pairs = this.selected_pairs.filter(x=> x != pair); return}
            this.selected_pairs.push(pair)
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
// .v-dialog {
//   /* Set the dialog to be positioned fixed in the center of the viewport */
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

.selected-pair{
    background:rgba(0, 183, 255, 0.336);
}

</style>