<template>
    <div>
        <v-container fluid class="max-height-window">
            <v-row>
                <v-col sm="12" md="3">
                    <v-sheet rounded="sm" v-if="lists">
                        <!-- {{lists}} -->
                        <div class="team-name"> Team {{selected_team.name}} </div>
                        <v-tabs centered color="white" >
                                <v-tab><v-icon class="ma-0">mdi-view-list</v-icon></v-tab>
                                <v-tab><v-icon class="ma-0">mdi-filter</v-icon></v-tab>
                                <v-tab><v-icon class="ma-0">mdi-robot-outline</v-icon></v-tab>
                                <v-tab-item> <!-- Static lists -->

                                    <v-menu v-model="listStaticCMOpen" :position-x="listStaticCMX" :position-y="listStaticCMY">
                                        <v-list>
                                            <v-list-item @click="dialog.static_settings = true">Settings</v-list-item>
                                            <v-list-item @click="handleMenuItemClick('Option 1')">Delete</v-list-item>
                                        </v-list>
                                    </v-menu>
                                    
                                    
    
                                    <h4 class="mt-1" >Portfolios</h4>
                                    <div  v-for="l in lists.tokens.static"  :key="l.id" class="ma-1">
                                        <v-btn  
                                        elevation="2" small 
                                        :color="selected.list.id == l.id ? `red` :`default`"  block 
                                        @click="select_list(l,'tokens.static')" 
                                        @contextmenu.prevent="select_list(l,'tokens.static'); showlistStaticCM($event)" 
                                        >{{l.name}}</v-btn>
                                    </div>
                                    <div class="ma-1">
                                        <v-btn  elevation="2" small light block @click="create_new_list()" ><v-icon class="ma-0">mdi-plus</v-icon></v-btn>
                                    </div>
                                </v-tab-item>
                                <v-tab-item>
                                    <h4 class="mt-1" >Filters</h4>
                                    <div v-for="l in lists.tokens.dynamic"  :key="l.id" class="pa-1">
                                        <v-btn class="pa-1"  elevation="2" small :color="selected.list.id == l.id ? `red` :`default`" block @click="select_list(l,'tokens.dynamic')" >{{l.name}}</v-btn>
                                    </div>
                                </v-tab-item>

                                <v-tab-item> 
                                    <h4 class="mt-1" >Neural Filters</h4>
                                    (coming soon) 
                                </v-tab-item>
                        </v-tabs>
        
                    </v-sheet>
                    
                </v-col>
                <v-col sm="12" md="9">
                    <v-sheet rounded="sm" class="pa-2">
                        <div v-if="selected.type == 'tokens.static'">
                            <intel-pair-list :list_id="selected.list.id" :list_type="selected.type"></intel-pair-list>
                        </div>
                        <div v-if="selected.type == 'tokens.dynamic'">
                            <h2>{{selected.list.name}}</h2>
                            <dynamic-list-now-listing-settings :listId="selected.list.id" :input_stream="selected.list.input_stream_fnc_name" :input_stream_args="selected.list.input_stream_args_now"></dynamic-list-now-listing-settings>
                        </div>
                        <div v-if="selected.type == 'traders.static'">
                            <h2>{{selected.list.name}}</h2>
                            <traders-portfolio-listing :listId="selected.list.id"></traders-portfolio-listing>
                        </div>
                    </v-sheet>
                </v-col>



                <v-col v-if="false" cols="12" md="9">
                    <v-tabs centered color="white" background-color="#121212f0">
                            <v-tab>static </v-tab>
                            <v-tab>dynamic </v-tab>
                            <v-tab-item> <view-my-lists-static></view-my-lists-static> </v-tab-item>
                            <v-tab-item> <pair-list-dynamic-view></pair-list-dynamic-view> </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>

   
        <v-dialog v-model="dialog.static_settings"  max-width="70vw" >
            <v-sheet rounded="sm">
                <my-list-static-info :listId="selected.list.id" @saved="dialog.static_settings = false; do_get_lists_for_team()"></my-list-static-info>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
import PairListDynamicView from './dynamic_lists/pair-list-dynamic-view.vue'
import IntelPairList from './static_lists/intel-pair-list.vue'
import ViewMyListsStatic from './static_lists/view-my-lists-static.vue'
import DynamicListNowListingSettings from './dynamic_lists/dynamic-list-now-listing-settings.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import MyListStaticInfo from './static_lists/my-list-static-info.vue'
import TradersPortfolioListing from './traders-portfolio-listing.vue'

export default {
    name: 'view-my-lists',
    components:{
        PairListDynamicView,
        ViewMyListsStatic,
        IntelPairList,
        DynamicListNowListingSettings,
        MyListStaticInfo,
        TradersPortfolioListing,
    },
    emits:[
    ],
    data(){return {
        listStaticCMOpen: false,
        listStaticCMX: 0,
        listStaticCMY: 0,
        selectedOption: null,

        dialog:{
            static_settings:false,
        },

        lists:undefined,
        selected:{
            list:{id:0},
            type:undefined,
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_lists_for_team()
    
    },
    methods:{
        ...mapActions(['get_lists_for_team']),
        do_get_lists_for_team(){
            this.get_lists_for_team({qs:{team_id:this.selected_team.id}}).then(r=>{
                this.lists = r.data
            })
        },
        select_list(list,type){
            console.log(list,MimeTypeArray);
            this.selected.list = list
            this.selected.type = type
        },
        showlistStaticCM(event) {
            event.preventDefault();
            this.listStaticCMX = event.clientX;
            this.listStaticCMY = event.clientY;
            this.listStaticCMOpen = true;
        },
        handleMenuItemClick(option, data) {
            this.selectedOption = option;
            this.listStaticCMOpen = false;
        },
    
    },
    computed:{
        ...mapState(['selected_team']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

.max-height-window{
    max-height: 90vh;
    overflow-y: scroll;
}
.team-name{
    text-align:center;
    text-transform:uppercase;
    font-size:0.8em;
    letter-spacing: 2px;
}

</style>