<template>
    <div>

        <v-container fluid >
            <v-row>


                <v-col sm="12" md="12">
                    
                    <v-tabs centered color="white" >
                            <v-tab>Now</v-tab>
                            <v-tab>Rolling</v-tab>
                            <v-tab-item>
                                <v-container fluid>
                                    <v-row>
  
                                        <v-col cols="12" md="12" class="mb-0 pb-0">
    
                                                <time-interval-selector :startTimeframe="'1H'" @interval="(e) => {query_form.back_interval = e ; do_explore_token_stream()}"></time-interval-selector>
                                        </v-col>
                                   
                                        <v-col sm="12" md="12" v-if="loading.query">
                                            <fake-update-loader :secs="9"></fake-update-loader>
                              

                                        </v-col>

                                        <v-col cols="12" md="12" class="mt-0 pt-0" v-else>
                                            <v-tabs v-if="token_stream" centered color="white" >
                                                    <v-tab v-for="tt in trade_types" :key="tt" >{{tt}}</v-tab>
                                                    <v-tab-item v-for="tt in trade_types" :key="tt">
                                                        <v-container fluid>
                                                            <v-row>
                                                                <v-col cols="12" md="12" class="mt-0 pt-0">
                                                                    <table>

                                                                            <tr>
                                                                                <th>liq</th>
                                                                                <th>token age</th>
                                                                                <th>pair</th>
                                                                                <th>sig age</th>
                                                                                <th>trades</th>
                                                                                <th>traders</th>
                                                                                <th>price x min</th>
                                                                                <th>price x max</th>
                                                                            </tr>
                                                                            <tr v-for="(token_data, token_id) in filtered_investment_list[tt]" :key="token_id"  >
                                                                                <td>
                                                                                    <!-- {{token_stream.tokens[token_id].liquidity}}  [{{token_stream.tokens[token_id].age}}]  -->
                                                                                    
                                                                                    <base-amount :amount="token_stream.tokens[token_id].liquidity"></base-amount>
                                                                                </td>
                                                                                <td>

                                                                                    <time-display :time="token_stream.tokens[token_id].age" mode="timedelta"></time-display>
                                                                                </td>
                                                                                <td><pair-link-to-page :pair="token_stream.tokens[token_id]"></pair-link-to-page></td>
                                                                                <td>  <time-display :time="get_first_trade_time(token_data)" :mode="'timesince'"></time-display> </td>
                                                                                <td @click="set_focus('trades',token_data.trades )" >{{token_data.trades.length}}</td>
                                                                                <td @click="set_focus('traders',token_data.traders )"> 
                                                                                    <!-- {{token_data.traders }}  -->
                                                                                    <div class="text-center" v-for="tid in token_data.traders" :key="tid">
                                                                                        <v-chip @click="selected.trader_id = tid"  :color="tid == selected.trader_id ? 'red' :'gray'" class="ma-1"  small > 
                                                                                            <trader-link-to-page :traderId="tid"></trader-link-to-page>
                                                                                        </v-chip>
                                                                                    </div>
                                                                                </td>
                                                                                <td>  
                                                                                    <div v-if="token_stream.tokens[token_id][`${tt}_price_info`].min_price_x">
                                                                                        <div :style="style_min_x(token_stream.tokens[token_id][`${tt}_price_info`].min_price_x)">

                                                                                            {{(token_stream.tokens[token_id][`${tt}_price_info`].min_price_x).toFixed(2)}}  
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td> 
                                                                                    <div v-if="token_stream.tokens[token_id][`${tt}_price_info`].max_price_x">
                                                                                        <div :style="style_max_x(token_stream.tokens[token_id][`${tt}_price_info`].max_price_x)">
                                                                                            {{(token_stream.tokens[token_id][`${tt}_price_info`].max_price_x).toFixed(2)}} 
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="6"> AVG x </td>
                                                                                <th> {{(( summary_rows[tt].min_price_x)).toFixed(2)}} </th>
                                                                                <th> {{((summary_rows[tt].max_price_x )).toFixed(2)}} </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colspan="8">
                                                                                    <div v-if="summary_rows[tt].rug_cnt">
                                                                                    </div>
                                                                                    <i>
                                                                                        ( {{summary_rows[tt].rug_cnt}} Rugs  of {{summary_rows[tt]['cnt']}} Tokens)
                                                                                    </i>
                                                                                </td>
                                                                            </tr>
                                                                    </table>
                                                                </v-col>
                                                                <v-col cols="12" md="12" v-if="focus.mode">
                                                                    
                                                                    <div v-if="focus.mode == 'trades'">
                                                                        <h5>Focused trades</h5>
                                                                            <table >
                                                                                <tr>
                                                                                    <th>id</th>
                                                                                    <th>hash</th>
                                                                                    <th>trader</th>
                                                                                    <th>time</th>
                                                                                    <th>amount</th>
                                                                                </tr>
                                                                                <tr v-for="d in focus.data" :key="d.id">
                                                                                    <td>{{d.id}}</td>
                                                                                    <td> <external-link-etherscan :hash="d.hash" :type="'tx'"></external-link-etherscan> </td>
                                                                                    <td> 
                                                                                        <!-- <trader-link-to-page :traderId="d.trader__id"></trader-link-to-page> -->
                                                                                        <v-chip @click="selected.trader_id = d.trader__id"  :color="d.trader__id == selected.trader_id ? 'red' :'gray'" class="ma-1" small > 
                                                                                            
                                                                                        </v-chip>
                                                                                    </td>
                                                                                    <td> <time-display :time="d.time_epoch"></time-display></td>
                                                                                    <td> <base-amount :amount="d.amount_base"></base-amount> </td>
                                                                                </tr>
                                                                            </table>
                                                                    </div>
                                                                    <div v-if="focus.mode == 'traders'">

                                                                        <h5>Focused traders</h5>
                                                                            <table >
                                                                                <tr> <th>id</th> <th>id</th> </tr>
                                                                                <tr v-for="d in focus.data" :key="d.id">
                                                                                    <td>

                                                                                        <trader-link-to-page :traderId="d.id"></trader-link-to-page>
                                                                                    </td>
                                                                                    <td>
                                                                                        <v-chip @click="selected.trader_id = d.id"  :color="d.id == selected.trader_id ? 'red' :'gray'" class="ma-1" small > {{d.id}}</v-chip>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                    </div>
                                                                </v-col>
                                                                <!-- detected wallet bonds
                                                                <v-col sm="12" md="12">
                                                                    
                                                                    <h5> Detected wallet  / bot groups</h5>
                                                                    <table>
                                                                            <tr> 
                                                                                <th>bond strength</th>
                                                                                <th> wallet / bot group</th>
                                                                            </tr>
                                                                            <tr v-for="(tg, i) in token_stream.trader_groups " :key="i">
                                                                                <td>{{tg.count}} </td>
                                                                                <td style="text-align:left">
                                                                                    <div style="display:flex; flex-wrap:wrap;" >
                                                                                        <div v-for="tid in tg.group" :key="tid"  >
                                                                                            <v-chip @click="selected.trader_id = tid"  :color="tid == selected.trader_id ? 'red' :'gray'" class="ma-1" small > 
                                                                                                <trader-link-to-page :traderId="tid"></trader-link-to-page>
                                                                                            </v-chip>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                    </table>
                                                                </v-col> -->
                                                            </v-row>
                                                        </v-container>




                                                    </v-tab-item>
                                            </v-tabs>
                                            
                                        </v-col>
                                        <v-col sm="12" md="12" v-if="token_stream">
                                            <div style="display:flex; flex-wrap:wrap;" >
                                                <div v-for="(meta,tid) in token_stream.trader_meta" :key="tid"  >
                                                    <v-chip @click="selected.trader_id = tid"  :color="tid == selected.trader_id ? 'red' :'gray'" class="ma-1" v-if="meta.cnt.buy != 0" small > 
                                                    <trader-link-to-page :traderId="tid"></trader-link-to-page>
                                                        ({{meta.cnt.buy}}x)
                                                    </v-chip>
                                                </div>

                                            <v-btn class="ma-1"  elevation="2" small v-if="selected.trader_id" @click="selected.trader_id = undefined" outlined  > <v-icon class="ma-0" small >mdi-close</v-icon> clear selection</v-btn>
                                            </div>
                                        </v-col>

                                    </v-row>
                                </v-container>

                                
                            </v-tab-item>
                            <v-tab-item>
                                <trader-pair-signals-rolling-test :listId="listId"></trader-pair-signals-rolling-test>
                            </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
import TimeIntervalSelector from '../time-interval-selector.vue'
import PairLinkToPage from '../pair-link-to-page.vue'
import BaseAmount from '../base-amount.vue'
import TraderLinkToPage from '../trader-link-to-page.vue'
import ExternalLinkEtherscan from '../external-link-etherscan.vue'
import TimeDisplay from '../time-display.vue'

// End Component Imports
import { mapState,mapActions } from 'vuex'
import TraderPairSignalsRollingTest from './trader-pair-signals-rolling-test.vue'
import FakeUpdateLoader from '../fake-update-loader.vue'
export default {
    name: 'trader-static-explore-tokens-traded',
    components:{
        TimeIntervalSelector,
        PairLinkToPage,
        BaseAmount,
        TraderLinkToPage,
        ExternalLinkEtherscan,
        TimeDisplay,
        TraderPairSignalsRollingTest,
        FakeUpdateLoader,

    },
    emits:[
    ],
    data(){return {
        trade_types: ['buy','sell','swap'],
        query_form:{
            time_now:0,
            back_interval:60,
            list_id: this.listId,
        },
        selected:{
            trader_id:undefined,
        },
        focus:{
            mode:undefined,
            ids:[],
            data:[],
        },
        token_stream:undefined,
        loading:{
            query:false,
        },

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        listId:{type:Number,default:Number},
    },
    mounted(){
        this.do_explore_token_stream()
    },
    methods:{
        ...mapActions(['static_trader_list_actions']),
        do_explore_token_stream(){
            this.loading.query = true
            this.static_trader_list_actions({qs:{action:'get_investement_stream'},data:this.query_form}).then((r)=>{
                this.token_stream = r.data
                this.loading.query = false
            })
        },

        set_focus(mode,ids){
            let data = []
            for (let i = 0; i   < ids.length; i++) {
                const id = ids[i]   ;
                if(mode == 'traders'){ data.push( ( {'id':id}) )}
                if(mode == 'trades'){ data.push( this.token_stream.trades[id])}
                
            }
            this.focus.data = data 
            this.focus.ids = ids
            this.focus.mode = mode
        },
        get_first_trade_time(token_data){
            return Math.min(...token_data.trades.map(t=> this.token_stream.trades[t].time_epoch))

        },
        style_max_x(amt){
            if(amt == 1){ return 'color:#ffffff50;'}
            if(amt > 1.5){ return 'color:green;'}
            return `color:white;`
        },
        style_min_x(amt){
            if(amt > 1){ return 'color:#ffffff50;'}
            if((amt - 0.5) < 0 ){ return 'color:red;'}
            return `color:#ff8500c4;`

        },
    },
    computed:{
        ...mapState([]),
        filtered_investment_list(){
            if (! this.token_stream ) return {'buy':[],'sell': [],'swap':[]}
            const trade_types = ['buy','sell','swap']
            let investments = JSON.parse(JSON.stringify(this.token_stream.investments))
            if (this.selected.trader_id) { 
                for (let i = 0; i < trade_types.length; i++) {
                    const tt = trade_types[i];
                    let pair_investment_obj = {}
                    for (const pair_id in investments[tt]) {
                        if (Object.hasOwnProperty.call(investments[tt], pair_id)) {
                            const inv = investments[tt][pair_id];
                            if(inv.traders.includes( parseInt(this.selected.trader_id))){
                                pair_investment_obj[pair_id] = inv
                            }
                            
                        }
                    }
                    investments[tt] = pair_investment_obj
                }
            }
            return investments

        },
        summary_rows(){
            const trade_types = ['buy','sell','swap']
            let summary_row = {
                'buy':{'max_price_x':0, 'min_price_x':0,'rug_cnt':0, 'cnt':0},
                'sell':{'max_price_x':0, 'min_price_x':0,'rug_cnt':0, 'cnt':0},
                'swap':{'max_price_x':0, 'min_price_x':0,'rug_cnt':0, 'cnt':0},
            
            }
            for (let i = 0; i < trade_types.length; i++) {
                const tt = trade_types[i];
                let inv_count = 0
                for (const pair_id in this.filtered_investment_list[tt]) {
                    if (Object.hasOwnProperty.call(this.filtered_investment_list[tt], pair_id)) {
                        // token_stream.tokens[token_id][`${tt}_price_info`].min_price_x
                        const inv = this.token_stream.tokens[pair_id];
                        // const inv = this.filtered_investment_list[tt][pair_id];
                        console.log(inv);
                        const mx_price_x = inv[`${tt}_price_info`].max_price_x
                        const mn_price_x = inv[`${tt}_price_info`].min_price_x

                        summary_row[tt]['max_price_x'] +=  mx_price_x
                        summary_row[tt]['min_price_x'] +=  mn_price_x
                        if(mn_price_x < 0.05){ summary_row[tt]['rug_cnt'] += 1 }
                        inv_count += 1
                        
                    }


                }
                summary_row[tt]['cnt'] = inv_count
                if(inv_count !=0){
                    summary_row[tt]['max_price_x'] = summary_row[tt]['max_price_x'] / inv_count
                    summary_row[tt]['min_price_x'] = summary_row[tt]['min_price_x'] / inv_count
                }
           
            }
            return summary_row
        }
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
td{
    text-align: center;
}
</style>