<template>
    <div>
        <center>
        {{msg}}
        </center>
         <v-progress-linear  :indeterminate="secs_left <= 0" stream :value="pct_done" color="white" ></v-progress-linear>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'fake-update-loader',
    components:{
    },
    emits:[
    ],
    data(){return {
        secs_left:undefined,
        pct_done:0,
        
        }
    },
    props: { 
        msg:{type:String,default:'Loading...'},
        //data:{type:Object,default:Object},
        secs:{type:Number,default:Number},
        updateRate:{type:Number,default:1},
    },
    mounted(){
        this.secs_left = this.secs + 0
        setInterval(this.update_pct_done, this.updateRate*1000)
    
    },
    methods:{
        ...mapActions([]),
        update_pct_done(){
            if (this.secs_left <= 0) return
            this.secs_left = this.secs_left - this.updateRate
            this.pct_done = (100*(this.secs - this.secs_left) / this.secs).toFixed(0)

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>