<template>
    <div>
        <v-container fluid>
            <v-row v-if="list_data">
                <v-col cols="12" md="12">
                    <h1>{{list_data.name}}</h1>
                    <h5>settings [{{list_data.id}}]</h5>
                </v-col>
                <v-col cols="12" md="12">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field label="Name" v-model="list_data.name" outlined dense ></v-text-field>
                                
                            </v-col>
                            <v-col cols="12" md="6">
                                Owned by {{list_data.owner.name}}
                            </v-col>
                            <v-col sm="12" md="12">
                                <v-textarea label="Notes" v-model="list_data.notes" outlined dense ></v-textarea>
                            </v-col>
                            <v-col sm="12" md="6">
                                
                            </v-col>
                            <v-col sm="12" md="6">
                                <v-btn class="ma-0"  elevation="2" small light block @click="save_list_info" >{{msg}} </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'my-list-static-info',
    components:{
    },
    emits:[
    ],
    data(){return {
        list_data:undefined,
        msg:'save',
        }
    },
    props: { 
        //propName:{type:String,default:''},
        listId:{type:Number,default:Number},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.get_list_info()
    },
    methods:{
        ...mapActions(['model_method','token_list_actions']),
        get_list_info(){
            this.model_method({model:'PairListStatic', method:'list_get', obj_id:this.listId}).then(r=>{
                this.list_data = r.data
              
            })
        },
        save_list_info(){
            const data_to_send =  {id:this.list_data.id, notes:this.list_data.notes, name:this.list_data.name}
            this.msg = 'saving...'
            this.token_list_actions({qs:{action:'update_list_info'},data:{
                list_id:this.listId, list_type:'tokens.static', list_data:data_to_send}}).then((r)=>{
                    this.$emit('saved')
                })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        listId(){this.get_list_info()}
    },
}
</script>
<style scoped lang="scss">
</style>