<template>
<div>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <div  v-bind="attrs" v-on="on" style="margin-right: 1em; margin-left:1em;">
                <div class="wrapper-div" style="width:100%; height:30px; background-color:transparent;">
                    <div class="bar-x1" :style="`background-color:#55c47af2; height:100%;  margin-left:${x1_min_margin_pct}%; width:${x1_width_pct}%; margin-right:${x1_max_margin_pct};`">
                        <div style="width: 50%; border-right: 5px solid black; height:2px;" > </div>
                        {{x1name}}
                    </div>
                </div>
                <div class="wrapper-div" style="width:100%; height:30px; background-color:transparent;">
                    <div class="bar-x2" :style="`background-color:#b2ae87f2; height:100%;  margin-left:${x2_min_margin_pct}%; width:${x2_width_pct}%; margin-right:${x2_max_margin_pct};`">
                        <div style="width: 50%; border-right: 5px solid black; height:2px;" > </div>
                        {{x2name}}
                    </div>
                </div>
            </div>
        </template>
        <div>
            <div> {{x1name}} = {{x1[0].toFixed(2)}} - {{x1[1].toFixed(2)}} x </div>
            <div> {{x2name}} = {{x2[0].toFixed(2)}} - {{x2[1].toFixed(2)}} x </div>
        </div>
    </v-tooltip>
</div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'duo-spread-average-displayer',
    components:{
    },
    emits:[
    ],
    data(){return {
        padding_pct :10,


        }
    },
    props: { 
        x1name:{type:String,default:''},
        x2name:{type:String,default:''},
        //data:{type:Object,default:Object},
        x1:{type:Array,default:Array},
        x2:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
        max_val(){
            const combinedArray = [...this.x1, ...this.x2];
            return Math.max(...combinedArray)
        },
        min_val(){
            const combinedArray = [...this.x1, ...this.x2];
            return Math.min(...combinedArray)
        },
        total_width_amt(){
            return( this.max_val - this.min_val)
                        
        },
        x1_min_margin_pct(){
            const val_width = this.x1[0] - this.min_val 
            console.log("VAL WIDTH", val_width, this.total_width_amt);
            return  100* val_width / this.total_width_amt 
        },
        x2_min_margin_pct(){
            const val_width = this.x2[0] - this.min_val 
            return  100* val_width / this.total_width_amt 
        },
        x1_width_pct(){
            const val_width = this.x1[1] -  this.x1[0]
            return  100* val_width / this.total_width_amt 
        },
        x2_width_pct(){
            const val_width = this.x2[1] -  this.x2[0]
            return  100* val_width / this.total_width_amt 
        },

        x1_max_margin_pct(){
            const val_width = (this.max_val - this.min_val) - (this.x1_min_margin_pct  + (this.x1[1] -  this.x1[0]))
            return  100* (val_width / this.total_width_amt)
        },
        x2_max_margin_pct(){
            const val_width = (this.max_val - this.min_val) - (this.x2_min_margin_pct  + (this.x2[1] -  this.x2[0]))
            return  100* val_width / this.total_width_amt 
        },

    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.data{
    font-size: 0.7em;
    color: rgba(255, 255, 255, 0.49);
    text-transform: capitalize;
}

.bar-x1, .bar-x2{
    text-transform: capitalize;
    border-radius: 2px;
    margin-top:2px;
}
</style>