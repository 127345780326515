<template>
    <div>
        <div v-if="loading" class="loading-wrap">
            <v-progress-circular indeterminate color="white"></v-progress-circular>
            <div>
                {{ loading_msg }} 

            </div>
        </div>
        <div v-else>
            <!-- {{ loading_msg }} -->
            <slot></slot>
        </div>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'is-loading-wrapper',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        loading_msg:{type:String,default:'Loading'},
        //data:{type:Object,default:Object},
        loading:{type:Boolean,default:false},
    },
    mounted(){
        console.log("LOADING MSG", this.loading_msg);
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.loading-wrap{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height:100px;
}
</style>