<template>
    <div>
        <v-container fluid>
            <v-row>


                <v-col cols="12" md="12">
                    <canvas id="cashChart" ></canvas>
                    <!-- <pre v-if="data"> {{ data }} </pre> -->
                </v-col>
       
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import {Chart, registerables} from 'chart.js'

Chart.register(...registerables);

export default {
    name: 'intel-defi-cashflow-graph',
    components:{
    },
    emits:[
    ],
    data(){
        return {
    
        charts:{},
        left_data:'flow',
        right_data:'usd_eth',
        is_loading:false,
        query:{
            interval:60*60*24,
            period:60*60*24*7,
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        labels:{type:Array,default:Array},
        x1:{type:Array,default:Array},
        x2:{type:Array,default:Array},
    },
    mounted(){
        this.do_defi_total_state_cashflow_graphs()
    
    },
    methods:{
        ...mapActions(['defi_total_state_cashflow_graphs']),
        do_defi_total_state_cashflow_graphs(){
            this.is_loading= true
            this.defi_total_state_cashflow_graphs({qs:this.query,data:{}}).then((r)=>{
                this.data = r.data;
                this.init_chart('cashChart')
                this.is_loading = false
            })
        },
        init_chart(chartName){

            try{this.charts[chartName].destroy()}catch{}
            const ctx = document.getElementById(chartName);




            this.charts[chartName] = new Chart(ctx, {
                type: 'line',
                data: {                    
                    datasets: [
                    {
                        label: 'x1',
                        data: {'label': this.labels, 'data':this.x1},
                        borderColor: 'red',
                        pointBorderColor: 'rgba(255, 0, 0, 0.1)', 
                        pointRadius: 2, 
                        fill: false,
                        backgroundColor:'rgba(255,255,0,0.3)',
                        tension: 0.5,
                        borderWidth:2,
                        yAxisID: 'y1',
                    },
                    {
                        label: 'x2',
                        data:  {'label': this.labels, 'data':this.x2},
                        borderColor: '#d1ffff',
                        pointBorderColor: 'rgba(255, 255, 255, 0.1)', 
                        pointRadius: 5, 
                        fill: true,
                        backgroundColor:'rgba(255,255,255,0.3)',
                        tension: 0.5,
                        borderWidth:2,
                        yAxisID: 'y1',
                    },

                ]},
                options: {               
                    scales: {
                        x: {
                            beginAtZero: false,
                            type: 'linear',
                            title: { display: true, text: 'Time', },
                            ticks: { callback:  (value, index, values) =>{ return this.formatTimestamp(value); }, },
                            },

                        y1: {
                            id:'y1',
                            beginAtZero: false,
                            title: { display: true, text: 'x1', },
                            position: 'left',
                            grid: {display:true , color:'rgba(255,355,255,0.1)'}

                        },
  
                    }
                }
            }
                );


        },

        formatTimestamp(timestamp) {
                 
            
            const now = new Date();
            const date = new Date(timestamp*1000)
            const isToday = date.getDate() === now.getDate() &&
                            date.getMonth() === now.getMonth() &&
                            date.getFullYear() === now.getFullYear();

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear()).slice(-2);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            if (isToday) {
                return `${hours}:${minutes}`;
            } else {
                return `${day}-${month}-${year} ${hours}:${minutes}`;
            }
        },
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>