<template>
    <div>
        <!-- NOT USED  -->
        <is-loading-wrapper :loading="false" loading_msg="Loading your lists...">

            <v-container fluid >
    
                <v-row>
                    <v-col cols="12" md="3">
                        <v-sheet rounded="sm" class="pa-1">
                            <h3> <v-icon class="ma-0"> mdi-format-align-top </v-icon> </h3>
                            <div v-for="dl in dynamic_lists_list" :key="dl.id">
                                <v-btn v-if="!dl.name.includes('ta')" class="mt-2"  :color="selected_list == dl ? 'red' : 'default'" elevation="2" small  block @click="select_list(dl)" >{{ dl.name }}</v-btn>
                            </div>
                            <div>
                                <v-btn class="mt-2"  elevation="2" small light  block @click="open_create_dialog"> + dynamic list</v-btn>
                            </div>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="9" v-if="selected_list">
                        <v-sheet rounded="sm">
                          
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <intel-pair-list-dynamic-list-edit :pair_dynamic_list_id="selected_list.id" :conditions="selected_list.conditions" @refresh="do_list_dynamic_lists"></intel-pair-list-dynamic-list-edit>
                                    </v-col>
                                    <v-col cols="12" md="9" v-if="selected_list_pair_ids">
                                        <is-loading-wrapper :is_loading="list_is_loading" :loading_msg="'Filtering tokens and compiling list'">
                                            intel pair list view custom has been depricated
                                            <!-- <intdepr el-pair-list-view-custom :view_name="`dynamic-list-${selected_list_id}`" :pair_ids="selected_list_pair_ids" ></intdeprel-pair-list-view-custom> -->
                                        </is-loading-wrapper>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </is-loading-wrapper>


        <v-dialog v-model="create_dl_dialog"  max-width="70vw" >
            <v-sheet rounded="sm">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <pair-dynamic-list-create @created="do_list_dynamic_lists(); create_dl_dialog=false" ></pair-dynamic-list-create>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-dialog>
  

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import IsLoadingWrapper from '@/components/is-loading-wrapper.vue'
import IntelPairListDynamicListEdit from '@/components/dynamic_lists/intel-pair-list-dynamic-list-edit.vue'
import PairDynamicListCreate from '@/components/dynamic_lists/pair-dynamic-list-create.vue'
export default {
    name: 'intel-pair-list-dynamic-view',
    components:{
        IntelPairListDynamicListEdit,
        PairDynamicListCreate,
        IsLoadingWrapper,
    },
    emits:[
    ],
   
    data(){return {
        dynamic_lists_list:undefined,
        selected_list_pair_ids:[],
        selected_list:undefined,
        dialog_details:false,
        selected_list_id:undefined,
        is_loading:false,
        list_is_loading:false,
        create_dl_form:{
            name:'',
            notes:''
        },
        create_dl_dialog:false,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_list_dynamic_lists()
        // this.list_get_list_pair_ids()
    },
    methods:{
        ...mapActions(['pair_list_dynamic_actions']),
        do_list_dynamic_lists(){
            this.is_loading = true
            this.selected_list = undefined
            this.pair_list_dynamic_actions({qs:{action:'list_dynamic_lists'},data:{}}).then((r)=>{
                this.dynamic_lists_list = r.data
                this.re_select_list_from_id()

            })
        },
        list_get_list_pair_ids(){
            this.list_is_loading = true
            this.pair_list_dynamic_actions({qs:{action:'list_get_list_pair_ids'},data:{pair_list_dynamic_id:this.selected_list_id}}).then((r)=>{
                this.selected_list_pair_ids = r.data
                this.list_is_loading = false
            })
        },
        do_create(){
            this.pair_list_dynamic_actions({qs:{action:'create'},data:{}}).then((r)=>{})
        },
        do_remove(){
            this.pair_list_dynamic_actions({qs:{action:'remove'},data:{}}).then((r)=>{})
        },
        do_update(){
            this.pair_list_dynamic_actions({qs:{action:'update'},data:{}}).then((r)=>{})
        },
        re_select_list_from_id(){
            if (this.selected_list_id){ 
                    this.selected_list = this.dynamic_lists_list.filter(x=> x.id == this.selected_list_id)[0] 
                }
        },
        select_list(dl){
            this.selected_list = dl
            this.selected_list_id = dl.id
            this.list_get_list_pair_ids()

        },
        open_create_dialog(){
            this.create_dl_dialog= true
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

</style>