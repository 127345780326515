<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="4">
                    <time-interval-selector label="period" :startTimeframe="'1H'" @interval="e => rolling_stats.query.batch_seconds = e "></time-interval-selector>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field label="periods back" rounded v-model="rolling_stats.query.periods_n" outlined dense type="number"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-btn class="ma-1"  elevation="2"  block @click="do_get_rolling_stats" > Signal </v-btn>
                </v-col>
                <v-col sm="12" md="2">
                    <v-btn class="ma-1"  elevation="2"  block @click="do_get_rolling_stats_random" > Noise </v-btn>
                    
                </v-col>

                <v-col cols="12" v-if="rolling_stats.result">
                    <table>
                        <tr>
                            <td></td>
                            <th> Buy signal</th>
                            <th v-if="has_random"> </th>
                            <th> sell signal</th>
                            <th v-if="has_random"> </th>
                        </tr>
                        <tr><th>Avg price swings </th> 
                            <td>

                                {{rolling_stats.result.full_aggregates.avg.price_min.buy.toFixed(2)}} - {{rolling_stats.result.full_aggregates.avg.price_max.buy.toFixed(2)}} x 
                                
                            </td> 
                            <td v-if="has_random" >
                                <duo-spread-average-displayer 
                                    :x1="[rolling_stats.result.full_aggregates.avg.price_min.buy, rolling_stats.result.full_aggregates.avg.price_max.buy]"
                                    :x2="[rolling_stats.random.full_aggregates.avg.price_min.buy, rolling_stats.random.full_aggregates.avg.price_max.buy]"
                                    x1name="signal"
                                    x2name="noise"
                                    ></duo-spread-average-displayer>


                                <!-- {{rolling_stats.random.full_aggregates.avg.price_min.buy.toFixed(2)}} - {{rolling_stats.random.full_aggregates.avg.price_max.buy.toFixed(2)}} x  -->
                            </td> 
                          
                            <td>{{rolling_stats.result.full_aggregates.avg.price_min.sell.toFixed(2)}} - {{rolling_stats.result.full_aggregates.avg.price_max.sell.toFixed(2)}} x </td> 
                            <td v-if="has_random"> 
                                    <duo-spread-average-displayer 
                                    :x1="[rolling_stats.result.full_aggregates.avg.price_min.sell, rolling_stats.result.full_aggregates.avg.price_max.sell]"
                                    :x2="[rolling_stats.random.full_aggregates.avg.price_min.sell, rolling_stats.random.full_aggregates.avg.price_max.sell]"
                                    x1name="signal"
                                    x2name="noise"
                                    ></duo-spread-average-displayer>
                                
                                
                                <!-- {{rolling_stats.random.full_aggregates.avg.price_min.sell.toFixed(2)}} - {{rolling_stats.random.full_aggregates.avg.price_max.sell.toFixed(2)}} x 
                                -->
                                </td>  
                        </tr>

                    
                        <tr><th> signals / period </th> 
                            <td>{{rolling_stats.result.full_aggregates.avg.signals.buy}}</td> 
                            <td v-if="has_random"  >{{rolling_stats.random.full_aggregates.avg.signals.buy.toFixed(2)}}</td> 
                            <td>{{rolling_stats.result.full_aggregates.avg.signals.sell.toFixed(2)}}</td> 
                            <td v-if="has_random"  >{{rolling_stats.random.full_aggregates.avg.signals.sell.toFixed(2)}}</td> 
                        </tr>
                
                        <tr><th> avg Rugs PP</th> 
                            <td>{{rolling_stats.result.full_aggregates.avg.rugs.buy.toFixed(2)}}</td> 
                            <td v-if="has_random" >{{rolling_stats.random.full_aggregates.avg.rugs.buy.toFixed(2)}}</td> 
                            <td>{{rolling_stats.result.full_aggregates.avg.rugs.sell.toFixed(2)}}</td> 
                            <td v-if="has_random" >{{rolling_stats.random.full_aggregates.avg.rugs.sell.toFixed(2)}}</td> 
                        
                        </tr>
                    
                    </table>
                        <v-btn-toggle class="ma-1">
                            <v-btn @click="selected.major_metric = 'cnt'"> Count </v-btn>
                            <v-btn @click="selected.major_metric = 'price_max_avg'"> price Max </v-btn>
                            <v-btn @click="selected.major_metric = 'price_min_avg'"> Price Min </v-btn>
                        </v-btn-toggle>
                        <v-btn-toggle class="ma-1">
                            <v-btn @click="selected.direction = 'buy'"> Buy </v-btn>
                            <v-btn @click="selected.direction = 'sell'"> Sell </v-btn>
                            <v-btn @click="selected.direction = 'swap'"> Swap </v-btn>
                        </v-btn-toggle>
                        {{selected.major_metric}}
                    <table>
                        <tr>
                            <th>start </th>
                            <th :colspan="has_random ? '2' :'1' ">{{selected.major_metric}} {{selected.direction}} </th>

                        </tr>

                        <tr v-for="(time_slice,i) in rolling_stats.result.metrics" :key="time_slice.start_epoch">
                            <td> 
                                <time-display :time="time_slice.start_epoch"></time-display>
                            </td>
                            <td> {{format_x(time_slice.performance[selected.major_metric][selected.direction]) }}   </td>
                            <td  v-if="has_random" >  {{format_x(rolling_stats.random.metrics[i].performance[selected.major_metric][selected.direction])}}</td>
                            <!-- <td>{{time_slice.performance}}</td> -->

                        </tr>

                    </table>
                    <!-- <rolling-signals-test-graph  :x1="[1,2,3]" :x2="[4,5,6]" :labels="[12,212,323]"></rolling-signals-test-graph> -->
                </v-col>
                <v-col sm="12" md="12" v-if="loading.rolling_stats">
                    <fake-update-loader :secs="rolling_stats.calc_rate*(rolling_stats.query.periods_n*rolling_stats.query.batch_seconds)"></fake-update-loader>
                </v-col>
                <v-col sm="12" md="12">
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
import TimeIntervalSelector from '../time-interval-selector.vue'
import FakeUpdateLoader from '../fake-update-loader.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TimeDisplay from '../time-display.vue'
import DuoSpreadAverageDisplayer from '../duo-spread-average-displayer.vue'
import RollingSignalsTestGraph from '../rolling-signals-test-graph.vue'
export default {
    name: 'trader-pair-signals-rolling-test',
    components:{
        TimeIntervalSelector,
        FakeUpdateLoader,
        TimeDisplay,
        DuoSpreadAverageDisplayer,
        RollingSignalsTestGraph,

    },
    emits:[

    ],
    data(){return {
        has_random:false,
          rolling_stats:{
            query:{
                batch_seconds:3600,
                periods_n:24,
                list_id: this.listId,
                random:false,
            },
            result:undefined,
            random:undefined,
            calc_rate:0.00027,

        },
        loading:{
            rolling_stats:false
        },
        selected:{
            major_metric:'cnt',
            direction:'buy'
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        listId:{type:Number,default:Number},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['static_trader_list_actions']),
        do_get_rolling_stats(){
            this.loading.rolling_stats = true
            this.static_trader_list_actions({qs:{action:'get_rolling_stats_on_list'},data:this.rolling_stats.query}).then((r)=>{
                this.rolling_stats.result = r.data
                this.loading.rolling_stats = false
                this.do_get_rolling_stats_random()
            })
        },
        do_get_rolling_stats_random(){
            this.loading.rolling_stats = true
            this.rolling_stats.query.random = true 
            this.static_trader_list_actions({qs:{action:'get_rolling_stats_on_list'},data:this.rolling_stats.query}).then((r)=>{
                this.rolling_stats.random = r.data
                this.loading.rolling_stats = false
                this.rolling_stats.query.random = false
                this.has_random = true
            })
        },

        format_x(nr){
            if (nr == undefined){ return  ' - '}
            if (this.selected.major_metric == 'cnt'){ return nr }
 
            return `${nr.toFixed(2)} x`

        }

    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
td{
    text-align: center;
}
</style>