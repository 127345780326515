<template>
    <div>
        <v-sheet rounded="sm" class="full-page">
            <v-tabs centered color="white" >
                    <v-tab>Traders</v-tab>
                    <v-tab>Pair Streams</v-tab>
   
                    <v-tab-item>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <!-- <v-btn class="ma-1"  elevation="2" small v-if="selected.trader_ids.length" @click="dialog.move_traders = !dialog.move_traders" > <v-icon class="ma-0" small>mdi-arrow-up</v-icon> Move selected traders  </v-btn> -->
                                    <!-- <v-btn class="ma-1"  elevation="2" small v-if="selected.trader_ids.length" @click="do_static_trader_list_actions_remove" color="red" > <v-icon class="ma-0" small >mdi-delete</v-icon> remove selected</v-btn> -->
                                    
                                </v-col>
                                <v-col cols="12" md="12">
                                    
                                    <div v-if="listings">
                                        <!-- {{selected.trader_ids}} -->

                                        <traders-group-table :traderIds="listings.traders.map(t => t.trader__id)" @traders_selected="(e)=>{selected.trader_ids = e}"></traders-group-table>

                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>

                        
                    </v-tab-item>
                    <v-tab-item>
                        <trader-static-explore-tokens-traded :listId="listId"></trader-static-explore-tokens-traded>
                    </v-tab-item>
            </v-tabs>

        </v-sheet>





        <v-dialog v-model="dialog.move_traders"  max-width="70vw" >
            <v-sheet rounded="sm">
                <my-list-selector :staticListType="'trader'" @list_selected="(l)=>{target_list = l}"></my-list-selector>
                <div v-if="target_list">
                    Move {{mechanics.selected.length}} traders to [{{target_list.name}}]
                </div>
                <v-btn class="ma-0"  elevation="2" block @click="do_static_trader_list_actions_move" > Move to list</v-btn>
            </v-sheet>
        </v-dialog>
        
    </div>
</template>
<script>
// Begin Component Imports
import MyListSelector from './static_lists/my-list-selector.vue'
import TraderStaticExploreTokensTraded from './static_lists/trader-static-explore-tokens-traded.vue'
import TimeDisplay from './time-display.vue'
import TraderLinkToPage from './trader-link-to-page.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import tradersGroupTable from './traders-group-table.vue'
export default {
    name: 'traders-portfolio-listing',
    components:{
        TraderLinkToPage,
        TraderStaticExploreTokensTraded,
        MyListSelector,
        TimeDisplay,
        tradersGroupTable,
    },
    emits:[  ],
    data(){return {
        listings:undefined,
        //EXPORTED START
        response:undefined,
        dialog:{
            scores:false,
            move_traders:false,
        },
        target_list:undefined,
        force_score_calc:false,
        disp_score_metric: 'avg',
        mechanics:{
            selected:[],
            order_by:'',
            range:{
                'col_name':undefined,
                'min':'',
                'max':'',
            }

        },
        trader_score_schema:{
            score_buy_signal:{          scores:{    max_price_multiplier:false},
                                        args_spec:{front_interval:60*60}},
            score_sell_signal:{         scores:{    min_price_multiplier:false},
                                        args_spec:{front_interval:60*60}},
            score_complete_pair_trades:{scores: {
                                                    sell_price_over_buy_price:false,
                                                    avg_hold_time:false,
                                                    pct_tokens_bought_not_sold:false,
                                                    pct_tokens_sold_not_bought:false,
                                                    pct_tokens_bought_and_sold:false,
                                                    total_tokens_invested:false,
                                                    trades_per_token:false, } ,
                                        args_spec:{}},
            score_day_hours_active:{    scores:{
                                                    day_pct_active_hrs:false},
                                        args_spec:{}},
            score_early_bird:{          scores:{
                                                    avg_th_buyer:false, 
                                                    avg_th_seller:false},
                                        args_spec:{}},
            },
        trader_score_data:{

        },
        score_cols:[],
        load_cnt : 0,
        selected:{
            trader_ids:[],
        }
        // EXPORTED END
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        listId:{type:Number,default:Number},
       
    },
    mounted(){
        this.do_static_trader_list_actions()
    
    },
    methods:{
        ...mapActions(['static_trader_list_actions','get_trader_score', 'force_recalc_trades']),
        do_static_trader_list_actions(){
            this.static_trader_list_actions({qs:{action:'get_listings'},data:{list_id:this.listId, status:'active'}}).then((r)=>{
                this.listings = r.data
            })
        },
        do_static_trader_list_actions_remove(){
            if (!confirm("Are you sure you want to delete these listings?")){ return }
            this.static_trader_list_actions({qs:{action:'remove_traders_from_list'},data:{list_id:this.listId, trader_ids:this.mechanics.selected }}).then((r)=>{
                this.do_static_trader_list_actions()
            })
        },
        do_static_trader_list_actions_move(){
            this.static_trader_list_actions({qs:{action:'move_traders_to_list'},data:{list_id:this.listId, target_list_id:this.target_list.id, trader_ids:this.mechanics.selected}}).then((r)=>{
                this.response = r.data
                this.dialog.move_traders = false
                this.target_list = undefined
                this.do_static_trader_list_actions()
            })
        },

        // Start Export 
        is_trader_selected(trader_id){
            return this.mechanics.selected.includes(trader_id)
        },
        trader_toggle_select(trader_id){
            if ( this.is_trader_selected(trader_id)){ 
                this.mechanics.selected = this.mechanics.selected.filter((x)=> x != trader_id)
            }else{
                this.mechanics.selected.push(trader_id)
            }
        },
        get_scores(score_name, score_schema){
            console.log("getting score...", score_name);
            this.trader_score_data = { } 
            for (let i = 0; i < this.listings.traders.length; i++) {
                const tid = this.listings.traders[i].trader__id;
                this.trader_score_data[tid] = {"trades":""}
                for (const score_name in score_schema.scores) {
                    if (Object.hasOwnProperty.call(score_schema.scores, score_name)) {
                        this.trader_score_data[tid][score_name] = "loading"
                    }
                }
                
            }

            this.score_cols = ["trades"]
            for (const score_name in score_schema.scores) {
                    if (Object.hasOwnProperty.call(score_schema.scores, score_name)) {
                        this.score_cols.push(score_name)
                    }
            }

            const score_command = [{
                score_name:score_name,
                args_spec: score_schema.args_spec
            }]

            const nr_of_traders = this.listings.traders.length
            this.load_cnt = 0 

            for (let i = 0; i < this.listings.traders.length; i++) {
                const tid = this.listings.traders[i].trader__id;
                this.get_trader_score({qs:{ trader_id:tid, forced:this.force_score_calc,return_dtype:'array'},data:{score_commands:score_command}}).then((r)=>{
                    
                    this.trader_score_data[tid]["trades"] = r.data.scores[0].index
                    for (const score_name in score_schema.scores) {
                        console.log(this.trader_score_data[tid][score_name],r.data.scores[0].score[score_name])
                        this.trader_score_data[tid][score_name] = r.data.scores[0].score[score_name]
                        this.load_cnt += 1
                        if (this.load_cnt == nr_of_traders){
                            this.dialog.scores = false
                        }
                    }

                })
            }
            
        this.dialog.scores = false



        },
        do_force_recalc_trades(){
            const trader_ids = this.listings.traders.map(a=>a.trader__id).join(',')
            this.force_recalc_trades({qs:{trader_ids:trader_ids},data:{}}).then((r)=>{

            })
        },
        range_select(){
            for (const tid in this.trader_score_data) {
                const score = this.trader_score_data[tid][this.mechanics.order_by];
                const min = parseFloat(this.mechanics.range.min)
                const max = parseFloat(this.mechanics.range.max)
                console.log(tid, score, min, max);
                if (score > min){
                    if( score < max){
                        console.log("Pushing ", this.mechanics.selected, tid);
                        this.mechanics.selected.push(parseInt(tid))
                    }
                }
            
            }
            this.mechanics.range.min = ''
            this.mechanics.range.max = ''

        },
        format_x_to_dec(nr, precision){
            try{ return nr.toFixed(precision)  }
            catch{ return nr }
        }

    },
    computed:{
        ...mapState([]),
        // Exported
        sorted_traders(){
            if (this.listings == undefined){ return []}
            if (this.mechanics.order_by == ''){ return this.listings.traders}
            return this.listings.traders.sort((a,b)=> {
                return this.trader_score_data[parseInt(b.trader__id)][this.mechanics.order_by][this.disp_score_metric] - this.trader_score_data[parseInt(a.trader__id)][this.mechanics.order_by][this.disp_score_metric]
                })
        }
    },
    watch:{
        listId(nv,ov){
            this.do_static_trader_list_actions()
        }
    },
}
</script>
<style scoped lang="scss">

td{
    text-align: center;
}
.selected_cell{
    background: #6130715e;
}
.selected_text{
    color:#b75ad65e;
}
.full-page{
    height:calc(100vh - 110px);
    overflow:scroll;
}


</style>