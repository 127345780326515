<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field class="ma-1" label="List name" rounded color="white" hide-details outlined dense v-model="new_list.name" ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea class="ma-1" label="list notes" rounded color="white" hide-details  outlined dense v-model="new_list.notes" ></v-textarea>
                </v-col>
                <v-col sm="12" md="12">
                    <v-btn style="float:right" class="ma-1" elevation="2" color="default" small  text @click="do_add_list"> save </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'pair-dynamic-list-create',
    components:{
    },
    emits:[
        'created'
    ],
    data(){return {
        new_list:{ name:'', notes:'', }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['pair_list_dynamic_actions']),
        do_add_list(){
            this.pair_list_dynamic_actions({data:this.new_list, qs:{action:'create_list'}}).then( r=> {
                    this.new_list = { name:'', notes:'' }
                    this.$emit('created')
            })
        }

    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>