<template>
    <div>
        <v-container fluid v-if="my_list">
            <h4>
                {{my_list.name}} 
           
            </h4>
            <v-btn class="ma-0"  elevation="2" small  @click="metrics_dialog= !metrics_dialog" > Avg Roi since add : {{ list_stats.roi.avg.toFixed(2) }} x </v-btn>
            <v-btn class="ma-1"  elevation="2" small  @click="do_get_trader_union_matrix" > get corralations</v-btn>
            <v-row>
                <v-col cols="12" md="12" v-if="pair_ids">
                    <intel-pair-list  :list_id="list_id" :list_type="'tokens.static'"  ></intel-pair-list>
                  
                </v-col>
                <v-col sm="12" md="12" v-else>
                    No pairs to display
                </v-col>

            </v-row>
            
            <v-dialog v-model="metrics_dialog"  max-width="70vw" >
                <v-sheet rounded="sm">
                    <v-container fluid v-if="list_stats.listings">
                        <v-row>
                            <v-col cols="12" md="12">
                                <table>
                                    <tr>
                                        <th>Token</th>
                                        <th>Entry</th>
                                        <th>Leave</th>
                                        <th>ROI</th>
                                    </tr>
                                    <tr v-for="l in list_stats.listings" :key="l.id">
                                        <td>
                                            <pair-link-to-page :pair="l.pair"></pair-link-to-page>
                                        </td>
                                        <td>{{ epochToFormattedDate(l.entry_time) }}</td>
                                        <td>{{ epochToFormattedDate(l.leave_time) }}</td>
                                        <td>{{ l.roi.toFixed(2) }}</td>
                                    </tr>
                                </table>
                            </v-col>
         
                        </v-row>
                    </v-container>
                    <!-- {{ list_stats }} -->
                </v-sheet>
            </v-dialog>

            <v-btn elevation="2" color="default" small @click="tum_dialog= !tum_dialog">open</v-btn>
            
            <!-- corralation Title  -->
            <v-dialog v-model="tum_dialog"   max-width="70vw" >
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12" v-if="trader_union_matrix_data">
                            <v-sheet rounded="sm" >
                                
                                
                                <table>
                                    <tr >
                                        <td></td>
                                        <th v-for="(tdata , tid1) in trader_union_matrix_data.tokens" :key="`ch${tid1}`"  style="height: 150px; max-width: 1em;">
                                            <div style="transform: rotate( 90deg ); ">

                                                {{ tdata.symbol.slice(0,10) }}
                                            </div>
                                        </th>
            
                                    </tr>
                                    <tr v-for="(tdata , tid1) in trader_union_matrix_data.tokens" :key="`r${tid1}`">
                                        <th > {{tdata.symbol}}</th>
                                        <td 
                                        v-for="(tdata , tid2) in trader_union_matrix_data.tokens" :key="`c${tid2}`" 
                                        :style="`text-align:center;background-color:rgba(255,0,0,${2*(trader_union_matrix_data.matrix[tid1][tid2] / trader_union_matrix_data.matrix[tid1][tid1])})`"
                                        > 
                                            <span v-if="tid1 == tid2">
                                                {{trader_union_matrix_data.matrix[tid1][tid2] }}
                                            </span>
                                            <span v-else-if="trader_union_matrix_data.matrix[tid1][tid2] !=0" style="font-size:0.8em"> 
                                                {{ (100* trader_union_matrix_data.matrix[tid1][tid2] / trader_union_matrix_data.matrix[tid1][tid1]).toFixed(1) }} %

                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <div style="text-align:center; width:100%">

                                    <i style=" color:gray"> Going along the row, x% of the traders that invested in the row , also invested in the col</i>
                                </div >
                            </v-sheet>
                            
                        </v-col>
                        <v-col cols="12" md="12" v-else>
                            <v-sheet rounded="sm">

                                Loading
                            </v-sheet>
                            
                        </v-col>
                    </v-row>
                </v-container>
            </v-dialog>

        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import PairListCustom from '@/components/pair-list-custom.vue'
import PairLinkToPage from '../pair-link-to-page.vue'
import IntelPairList from './intel-pair-list.vue'
export default {
    name: 'my-list-list',
    components:{
        PairListCustom,
        IntelPairList,
        PairLinkToPage
    },
    emits:[
    ],
    data(){return {
        my_list:undefined,
        pair_ids:undefined,
        list_stats:undefined,
        metrics_dialog:false,
        trader_union_matrix_data:undefined,
        tum_dialog:false,
        }
    },
    props: { 
        list_id:{type:Number,default:0},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_list_data()
    },
    methods:{
        ...mapActions(['static_pair_list_actions','trader_union_matrix']),
        do_get_list_data(){
                this.static_pair_list_actions({data:{list_id:this.list_id, status:'active'},qs:{action:'get_list_data'}}).then( r=> {
                    this.my_list = r.data
                    this.pair_ids = r.data.pairs.map(x=>x.pair.id)
                    this.list_stats = r.data.list_stats
                })
        },
        do_get_trader_union_matrix(){
            // pair_list_static_id
            this.tum_dialog = true
            this.trader_union_matrix({qs:{pair_list_static_id:this.list_id}}).then(r=>{
                this.trader_union_matrix_data = r.data
            })
        },
        pct_to_color(pct){

        },
        epochToFormattedDate(epoch) {
          const date = new Date(epoch * 1000);
          // Extract day, month, hours, and minutes from the date object
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${day}-${month} ${hours}:${minutes}`;
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        list_id(nv,ov){
            this.do_get_list_data()
        },
    },
}
</script>
<style scoped lang="scss">
</style>